import type { ICountry } from './Country';
import Country from './Country';
import type { IProduct } from './Product';
import Product from './Product';
import type { IMarketplace } from '@/api/model/Marketplace';
import Marketplace from '@/api/model/Marketplace';
import { DateTime } from 'luxon';

import type { WarehouseSKUJson } from './WarehouseSKU';
import WarehouseSKU from './WarehouseSKU';
export interface ISKU {
    id: number;
    created_at: string;
    updated_at: string;
    sku_id: string;
    asin: string;
    name: string;
    product_id: number;
    product_name: string | null;
    product?: IProduct;
    countries: Array<ICountry>;
    marketplaces: Array<IMarketplace>;
    sale_marketplaces: Array<IMarketplace> | null;
    is_hidden: boolean;
    warehouse_sku?: WarehouseSKUJson;
}

export type WithWarehouseSKU = {
    warehouse_sku: WarehouseSKU;
};

export default class SKU {
    id = 0;
    created_at: DateTime = DateTime.now();
    updated_at: DateTime = DateTime.now();
    sku_id = '';
    asin = '';
    name = '';
    product_id = 0;
    product?: Product = undefined;
    product_name?: string = undefined;
    countries: Array<Country> = [];
    marketplaces: Array<Marketplace> = [];
    sale_marketplaces: Array<Marketplace> = [];
    is_hidden = false;

    toJSON(): ISKU {
        //console.debug("SKU.toJSON()", this);
        return Object.assign({} as ISKU, this, {
            created_at: undefined,
            updated_at: undefined,
        });
    }

    static fromJSON(json: ISKU): SKU {
        //console.debug("SKU.fromJSON()", json);
        const user = Object.create(SKU.prototype);
        return Object.assign(user, json, {
            created_at: DateTime.fromISO(json.created_at),
            updated_at: DateTime.fromISO(json.updated_at),
            countries: json.countries?.map((country) => Country.fromJSON(country)) ?? [],
            product: json.product ? Product.fromJSON(json.product) : undefined,
            marketplaces: json.marketplaces?.map((marketplace) => Marketplace.fromJSON(marketplace)) ?? [],
            sale_marketplaces: json.sale_marketplaces?.map((marketplace) => Marketplace.fromJSON(marketplace)) ?? [],
            warehouse_sku: json.warehouse_sku ? WarehouseSKU.fromJSON(json.warehouse_sku) : undefined,
        });
    }

    getName() {
        if (this.product) {
            return `${this.product.name} (${this.sku_id})`;
        }

        return this.sku_id ?? 'Unknown';
    }
}
