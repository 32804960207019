import axios from 'axios';
import type { WarehouseTransferJson } from './model/WarehouseTransfer';
import WarehouseTransfer from './model/WarehouseTransfer';
import type { PaginatedResponseJson } from '@/api/model/PaginatedResponse';
import { PaginatedResponse } from '@/api/model/PaginatedResponse';
import type PageOptions from '@/api/model/PageOptions';
import type { Organisation } from '@/api/model/Organisation';
import type { Resource } from '@/api/model/Resource';
import type { SuccessResponse } from './types/Organisation';
import type { ResourceCollection } from '@/api/model/ResourceCollection';

export default function useWarehouseTransferService() {
    return {
        async getAll(
            org: Organisation,
            showCompleted: boolean,
            showDraft: boolean,
            showLive: boolean,
            showProblems: boolean,
            pageOptions: PageOptions,
        ): Promise<PaginatedResponse<WarehouseTransfer>> {
            const query = pageOptions.toURLSearchParams();
            query.append('show_completed', showCompleted ? '1' : '0');
            query.append('show_draft', showDraft ? '1' : '0');
            query.append('show_live', showLive ? '1' : '0');
            query.append('show_problems', showProblems ? '1' : '0');
            const response = await axios.get<PaginatedResponseJson<WarehouseTransferJson>>(
                `/api/organisations/${org.ulid}/transfers?${query.toString()}`,
            );
            return PaginatedResponse.fromJSON(response.data, (json: WarehouseTransferJson) =>
                WarehouseTransfer.fromJSON(json),
            );
        },

        async create(org: Organisation, data: WarehouseTransfer): Promise<WarehouseTransfer> {
            const response = await axios.post<Resource<WarehouseTransferJson>>(
                `/api/organisations/${org.ulid}/transfers`,
                data.toJSON(),
            );
            return WarehouseTransfer.fromJSON(response.data.data);
        },

        async update(org: Organisation, data: WarehouseTransfer): Promise<WarehouseTransfer> {
            const response = await axios.put<Resource<WarehouseTransferJson>>(
                `/api/organisations/${org.ulid}/transfers/${data.id}`,
                data.toJSON(),
            );
            return WarehouseTransfer.fromJSON(response.data.data);
        },

        async saveTicket(
            org: Organisation,
            transfer: WarehouseTransfer,
            ticketNumber: string,
        ): Promise<WarehouseTransfer> {
            const response = await axios.put<Resource<WarehouseTransferJson>>(
                `/api/organisations/${org.ulid}/transfers/${transfer.id}/ticket`,
                { ticket_number: ticketNumber },
            );
            return WarehouseTransfer.fromJSON(response.data.data);
        },

        async showWarehouseTransfer(org: Organisation, id: number): Promise<WarehouseTransfer> {
            const response = await axios.get<Resource<WarehouseTransferJson>>(
                `/api/organisations/${org.ulid}/transfers/${id}`,
            );
            return WarehouseTransfer.fromJSON(response.data.data);
        },

        async getPurchaseOrderWarehouseTransfers(org: Organisation, po_id: number): Promise<WarehouseTransfer[]> {
            const response = await axios.get<ResourceCollection<WarehouseTransferJson>>(
                `/api/organisations/${org.ulid}/purchase-orders/${po_id}/transfers`,
            );
            return response.data.data.map((product) => WarehouseTransfer.fromJSON(product));
        },

        async updatePurchaseOrderWarehouseTransfers(
            org: Organisation,
            po_id: number,
            data: number[],
        ): Promise<WarehouseTransfer[]> {
            const response = await axios.put<ResourceCollection<WarehouseTransferJson>>(
                `/api/organisations/${org.ulid}/purchase-orders/${po_id}/transfers`,
                { transfer_ids: data },
            );

            return response.data.data.map((transfer) => WarehouseTransfer.fromJSON(transfer));
        },

        async deletePurchaseOrderWarehouseTransfer(
            org: Organisation,
            po_id: number,
            transfer_id: number,
        ): Promise<void> {
            await axios.delete<SuccessResponse>(
                `/api/organisations/${org.ulid}/purchase-orders/${po_id}/transfers/${transfer_id}`,
            );
        },
        async getCounts(organisation: Organisation) {
            const response = await axios.get<{
                draftCount: number;
                problemCount: number;
            }>(`/api/organisations/${organisation.ulid}/transfers/counts`);
            return response.data;
        },
    };
}
